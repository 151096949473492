import './LoginPage.css'
import { Fragment, useState } from 'react'
import Input from "../Input"
import * as api from '../../lib/api/index'
import { render } from '../..'

const LoginPage = () => {
    const [email, setEmail] = useState('')
    const [otp, setOtp] = useState('')
    const [error, setError] = useState<Error>()
    const [otpToken, setOtpToken] = useState('')

    const requestOtp = async (e: React.FormEvent) => {
        e.preventDefault()
        const otpToken = await api.auth.requestOtp(setError, email)
        if (otpToken) {
            setOtpToken(otpToken)
        }
        
    }

    const verifyOtp = async (e: React.FormEvent) => {
        e.preventDefault()
        const res = await api.auth.verifyOtp(setError, otp, otpToken)
        if (res) {
            render()
        }
        
    }

    return (
        <Fragment>
            <div className="page-container">
                <p className={`error-text${error ? ' error-text--show' : ''}`}><span>{error?.message}</span></p>
            </div>
            
            <div className="login">
                <h1>gitRelease</h1>
                <h2>Log In</h2>
                {!otpToken && (
                    <Fragment>
                        <p>We'll send a login code to your email address</p>
                        <form action="" onSubmit={requestOtp}>
                            <Input
                                id="email"
                                labelText="Email Address"
                                type="email"
                                value={email}
                                onChange={(val) => setEmail(String(val))}
                            />
                            <button className="button">Submit</button>
                        </form>
                    </Fragment>
                )}
                
                {!!otpToken && (
                    <Fragment>
                        <p>Enter the login code we have just emailed to: <strong>{email}</strong></p>
                        <form action="" onSubmit={verifyOtp}>
                            <Input
                                id="otp"
                                labelText="Login Code"
                                type="text"
                                value={otp}
                                onChange={(val) => setOtp(String(val))}
                            />
                            <button className="button">Log In</button>
                        </form>
                    </Fragment>
                )}
            </div>
        </Fragment>
    )
}

export default LoginPage
