import { SetError, axiosInstance } from "."
import PRDto from "./interfaces/pr.dto.interface"
import Release from "./interfaces/release.interface"

export const create = async (setError: SetError, head: string, base: string, repos: string[]) => {
    try {
        const body = {
            head,
            base,
            repos
        }
        const { data } = await axiosInstance.post<{ id: string}>('/release', body)
        setError(undefined)
        return data
    } catch (error) {
        console.error(error)
        setError(error)
    }
}

export const getAll = async (setError: SetError) => {
    try {
        const { data } = await axiosInstance.get<Release[]>('/release')
        setError(undefined)
        return data
    } catch (error) {
        console.error(error)
        setError(error)
    }
    
}

export const get = async (setError: SetError, id: string) => {
    try {
        const { data } = await axiosInstance.get<Release>(`/release/${id}`)
        setError(undefined)
        return data 
    } catch (error) {
        console.error(error)
        setError(error)
    }
    
}

export const getReleaseNotes = async (setError: SetError, id: string) => {
    try {
        const { data } = await axiosInstance.get<string>(`/release/${id}/release-notes`)
        setError(undefined)
        return data
    } catch (error) {
        console.error(error)
        setError(error)
    }
    
}

export const updateReleaseNotes = async (setError: SetError, id: string, releaseNotes: string) => {
    try {
        const { data } = await axiosInstance.patch<Release>(
            `/release/${id}/release-notes`,
            { releaseNotes }
          )
        setError(undefined)
        return data
    } catch (error) {
        console.error(error)
        setError(error)
    }
}

export const updateNotes = async (setError: SetError, id: string, notes: string) => {
    try {
        const { data } = await axiosInstance.patch<Release>(
            `/release/${id}/notes`,
            { notes }
          )
        setError(undefined)
        return data
    } catch (error) {
        console.error(error)
        setError(error)
    }
}

export const close = async (setError: SetError, id: string) => {
    try {
        const { data } = await axiosInstance.patch<Release>(`/release/${id}/close`)
        setError(undefined)
        return data
    } catch (error) {
        console.error(error)
        setError(error)
    }
}

export const getRepo = async (setError: SetError, id: string, repo: string) => {
    try {
        const { data } = await axiosInstance.get<PRDto>(`/release/${id}/${repo}`)
        return data
    } catch (error) {
        console.error(error)
        setError(error)
    }
}

export const mergeRepoPR = async (setError: SetError, id: string, repo: string) => {
    try {
        const { data } = await axiosInstance.post<PRDto>(`/release/${id}/${repo}/merge`)
        setError(undefined)
        return data
    } catch (error) {
        console.error(error)
        setError(error)
    }
}

export const mergeAllPRs = async (setError: SetError, id: string) => {
    try {
        const { data } = await axiosInstance.post<Release>(`/release/${id}/merge-all`)
        setError(undefined)
        return data
    } catch (error) {
        console.error(error)
        setError(error)
    }
}

export const supplement = async (setError: SetError, id: string) => {
    try {
        const { data } = await axiosInstance.post<Release>(`/release/${id}/supplement`)
        setError(undefined)
        return data
    } catch (error) {
        console.error(error)
        setError(error)
    }
}
