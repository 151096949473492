import axios from 'axios'
import * as release from './release'
import * as dashboard from './dashboard'
import * as user from './user'
import * as auth from './auth'

export const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL || 'http://localhost:3000'
})

export type SetError = (error?: Error) => any


export { release }
export { dashboard }
export { user }
export { auth }
