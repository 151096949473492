import { BrowserRouter, Route, Switch } from 'react-router-dom'
import CreateReleasePage from '../../components/pages/CreateReleasePage'
import DashboardPage from '../../components/pages/DashboardPage'
import ViewReleasePage from '../../components/pages/ViewReleasePage'
import * as api from '../api'

const AppRouter = () => {
    return (
        <BrowserRouter>
            <nav className="nav">
                <h1>gitRelease</h1>
                <button className="button button--small" onClick={api.auth.logout}>Logout</button>
            </nav>
            <Switch>
                <Route path="/" exact={true} component={DashboardPage} />
                <Route path="/release/new" component={CreateReleasePage} />
                <Route path="/release/:releaseId" component={ViewReleasePage} />
                <Route render={() => <h1>404</h1>} />
            </Switch>
        </BrowserRouter>
    )
}

export default AppRouter
