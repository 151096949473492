import { axiosInstance, SetError } from "."
import { render } from "../.."
import User from "./interfaces/user.interface"

export const requestOtp = async (setError: SetError, email: string) => {
    try {
        const { data } = await axiosInstance.post<{ otpToken: string }>('/auth/otp/send', { email })
        return data.otpToken    
    } catch (error) {
        console.error(error)
        setError(error)
    }
    
}

export const verifyOtp = async (setError: SetError, otp: string, otpToken: string) => {
    try {
        const headers = {
            otp_token: otpToken
        }
        const { data } = await axiosInstance.post<{ user: User, accessToken: string }>('/auth/otp/verify', { otp }, { headers })
    
        setAccessToken(data.accessToken)
    
        return data.user    
    } catch (error) {
        console.error(error)
        setError(error)
    }
    
}

export const logout = async () => {
    clearAccessToken()
    render()
}

export const setAccessToken = (accessToken: string) => {
    window.localStorage.setItem('accessToken', accessToken)
    axiosInstance.defaults.headers.access_token = accessToken
}

export const getAccessToken = () => {
    const accessToken = window.localStorage.getItem('accessToken')
    axiosInstance.defaults.headers.access_token = accessToken
    return accessToken
}

export const clearAccessToken = () => {
    window.localStorage.removeItem('accessToken')
    axiosInstance.defaults.headers.access_token = undefined
}
