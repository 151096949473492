import './ViewReleasePage.css'
import React, { Fragment, useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import * as api from '../../lib/api'
import Release from "../../lib/api/interfaces/release.interface"
import PRList from "../PRList"
import ReleaseNotes from "../ReleaseNotes"
import ReleaseStatus from '../../lib/enums/release-status.enum'

export type SetRelease = (release: Release) => void

const ViewReleasePage = () => {
    const [release, setRelease] = useState<Release | undefined>()
    const [error, setError] = useState<Error>()
    const { releaseId } = useParams() as unknown as { releaseId: string }

    const getRelease = async () => {
        const release = await api.release.get(setError, releaseId)
        setRelease(release)
    }

    useEffect(() => {
        if (!release && !error) {
            getRelease()
        }
    })

    const supplementRelease = async () => {
        const updatedRelease = await api.release.supplement(setError, releaseId)
        if (updatedRelease) {
            setRelease(updatedRelease)
        }
    }

    const mergeAllPRs = async () => {
        const updatedRelease = await api.release.mergeAllPRs(setError, releaseId)
        if (updatedRelease) {
            setRelease(updatedRelease)
        }
    }

    const closeRelease = async () => {
        const updatedRelease = await api.release.close(setError, releaseId)
        if (updatedRelease) {
            setRelease(updatedRelease)
        }
    }

    return (
        <div className="page-container">
            <p className={`error-text${error ? ' error-text--show' : ''}`}><span>{error?.message}</span><br />{JSON.stringify(error)}</p>
            <Link to="/">Back to dashboard page</Link>
            <h3>View release</h3>
            {!release && (
                <p>Loading release...</p>
            )}

            {!!release && (
                <Fragment>
                    <h1>{release.base} to {release.head}</h1>
                    <p>Release {releaseId}</p>
                    <p>Created at {release.createdAt}</p>
                    <div className="release-actions">
                        <button className="button" disabled={release.status === ReleaseStatus.closed} onClick={supplementRelease}>Get Commit data for release notes 🗂</button>
                        <button className="button" disabled={release.status === ReleaseStatus.closed} onClick={mergeAllPRs}>Merge all PRs 💣</button>
                        <button className="button" disabled={release.status === ReleaseStatus.closed} onClick={closeRelease}>Close release 🚫</button>
                    </div>
                    <div className="release-data">
                        <PRList PRs={release.PRs} />
                        <ReleaseNotes
                            releaseNotes={release.releaseNotes || ''}
                            setError={setError}
                            setRelease={setRelease}
                            releaseId={releaseId}
                        />
                    </div>
                </Fragment>
            )}
        </div>
    )
}

export default ViewReleasePage