import './CreateReleasePage.css'
import { useEffect, useState, Fragment } from "react"
import { Link, useHistory } from "react-router-dom"
import * as api from '../../lib/api'
import ErrorMessage from '../ErrorMessage'
import Customer from '../../lib/api/interfaces/customer.interface'
import Input from '../Input'

enum ReleaseOption {
    alphaToStaging = 'alpha_staging',
    stagingToProd = 'staging_production'
}

const CreateReleasePage: React.FC = () => {
    const [error, setError] = useState<Error>()
    const [customer, setCustomer] = useState<Customer | undefined>()
    const [reposToInclude, setReposToInclude] = useState<string[]>([])

    const history = useHistory()

    const createRelease = async (releaseOption: ReleaseOption) => {
        const [head, base] = releaseOption.split('_')

        const res = await api.release.create(setError, head, base, reposToInclude)
        if (res) {
            history.push(`/release/${res.id}`)
        }
    }

    const getCustomer = async () => {
        const userFromApi = await api.user.getUser(setError)
        if (userFromApi) {
            setCustomer(userFromApi.customer)
        }
    }

    useEffect(() => {
        if (!customer) {
            getCustomer()
        }
    }, [])

    

    return (
        <div className="page-container">
            <ErrorMessage error={error} />
            <Link to="/">Back to dashboard page</Link>
            <h1>Create Release</h1>
            
            {!!customer && (
                <Fragment>
                    <p>Select repos to include</p>
                    <button className="button button--small" onClick={() => {
                        if (reposToInclude.length === customer.repos.length) {
                            setReposToInclude([])
                        } else {
                            setReposToInclude(customer.repos)
                            
                        }
                        
                    }}>{reposToInclude.length === customer.repos.length ? 'Unselect all' : 'Select all'}</button>
                    <br /><br />
                    {customer.repos.map((repo) => {
                        return <Input
                            id={`repo-${repo.toLowerCase()}`}
                            key={repo}
                            type="checkbox"
                            labelText={repo}
                            value={reposToInclude.includes(repo)}
                            onChange={(checked) => {
                                if (checked && !reposToInclude.includes(repo)) {
                                    setReposToInclude([...reposToInclude, repo])
                                    return
                                }

                                if (!checked && reposToInclude.includes(repo)) {
                                    setReposToInclude(reposToInclude.filter((repoToInclude) => repoToInclude !== repo))
                                    return
                                }
                            }}
                        />
                    })}
                </Fragment>
            )}
            <div style={{marginTop: '32px' }}>
                <button style={{marginRight: '8px'}} className="button" onClick={() => createRelease(ReleaseOption.alphaToStaging)}>Alpha to Staging 🚗</button>
                <button className="button" onClick={() => createRelease(ReleaseOption.stagingToProd)}>Staging to Production 🏎</button>
            </div>
            
        </div>
    )
}

export default CreateReleasePage
