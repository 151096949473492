import React, { useEffect, useState } from "react"
import RepoDiff from "../../lib/api/interfaces/repo-diff.interface"
import * as api from '../../lib/api'
import ErrorMessage from "../ErrorMessage"
import RepoDiffTile from "../RepoDiffTile"
import Customer from "../../lib/api/interfaces/customer.interface"
import Input from "../Input"

const DashboardPage = () => {
    const [error, setError] = useState<Error>()
    const [repos, setRepos] = useState<RepoDiff[]>([])
    const [customer, setCustomer] = useState<Customer | undefined>()
    const [release, setRelease] = useState<string>('0')

    const getRepos = async () => {
        const applicableRelease = customer?.applicableReleases[Number(release)]
        let head = 'alpha'
        let base = 'staging'
        if (applicableRelease) {
            head = applicableRelease.head
            base = applicableRelease.base
        }
        const reposFromApi = await api.dashboard.getRepoDiffs(setError, head, base)
        if (reposFromApi) {
            setRepos(reposFromApi)
        }
    }

    const getCustomer = async () => {
        const userFromApi = await api.user.getUser(setError)
        if (userFromApi) {
            setCustomer(userFromApi.customer)
        }
    }

    useEffect(() => {
        if (!repos.length) {
            getRepos()
        }

        if (!customer) {
            getCustomer()
        }
    }, [])

    useEffect(() => {
        getRepos()
    }, [release])

    const applicableReleases = []
    if (customer) {
        applicableReleases.push(...customer.applicableReleases.map((applicableRelease, index) => {
            return {
                label: `${applicableRelease.head} to ${applicableRelease.base}`,
                value: String(index)
            }
        }))
    }

    return (
        <div className="page-container">
            <ErrorMessage error={error} />
            <a href="/release/new">Create new release</a>
            <h1>gitRelease</h1>
            <Input
                id="applicable-release"
                labelText="Select branches to compare"
                type="select"
                options={applicableReleases}
                value={release}
                onChange={(val) => setRelease(String(val))}
            />
            {!repos.length && <p>Loading...</p>}
            {!!repos.length && (
                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr'}}>
                    {repos.map((repo) => <RepoDiffTile key={repo.repo} repoDiff={repo} />)}
                </div>
            )}
        </div>
    )
}

export default DashboardPage
