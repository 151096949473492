import './Input.css'
import React, { useState } from 'react';
import { validateEmail, validatePassword, parseDate } from '../utils';

interface IInputProps {
    id: string;
    labelText: string;
    helpText?: string;
    type: 'text' | 'number' | 'date' | 'search' | 'email' | 'password' | 'tel' | 'hidden' | 'select' | 'radio-group' | 'checkbox';
    value: string | number | boolean;
    onChange: (val: string | number | boolean) => void;
    placeholder?: string;
    disabled?: boolean;
    invalid?: boolean;
    options?: { value: string; label: string}[];
}

export const Input: React.FC<IInputProps> = ({ id, labelText, helpText, type, value, onChange, placeholder, disabled, invalid, options = [] }) => {
    const [dateString, setDateString] = useState('');
    const [emailString, setEmailString] = useState('');
    const [passwordString, setPasswordString] = useState('');
    const [isInvalid, setIsInvalid] = useState(false);
    const inputType = type === 'date' ? 'text' : type;

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        if (type === 'date') {
            setDateString(e.target.value.slice(0, 10));
            if (!e.target.value) {
                onChange(e.target.value);
                return;
            }
            const parsedDate = parseDate(e.target.value.slice(0, 10));
            if (!parsedDate) {
                setIsInvalid(true);
                onChange('');
            } else {
                setIsInvalid(false);
                onChange(parsedDate.toISOString().slice(0, 10));
            }

            return;
        }

        if (type === 'email') {
            setEmailString(e.target.value);
            if (!e.target.value) {
                onChange(e.target.value);
                return;
            }
            if (validateEmail(e.target.value) !== true) {
                setIsInvalid(true);
                onChange('');
            } else {
                setIsInvalid(false);
                onChange(e.target.value);
            }
        }

        if (type === 'password') {
            setPasswordString(e.target.value);
            if (!e.target.value) {
                onChange(e.target.value);
                return;
            }
            const validationRes = validatePassword(e.target.value);
            if (validationRes !== true) {
                setIsInvalid(true);
                onChange('');
            } else {
                setIsInvalid(false);
                onChange(e.target.value);
            }
        }

        onChange(e.target.value);
    }

    const isMobileRegex = /iPhone|Android/gm;
    const isIphoneRegex = /iPhone/gm;
    const isMobile = isMobileRegex.test(window.navigator.userAgent);
    const isIphone = isIphoneRegex.test(window.navigator.userAgent);

    let helpTextToRender = helpText;
    if (type === 'date') {
        const inputHelpText = helpText ? `- ${helpText}` : '';
        helpTextToRender = isMobile ? `${isIphone ? 'DD,MM,YYYY or ' : ''}DD.MM.YYYY or YYYY.MM.DD${inputHelpText}` : `DD/MM/YYYY or YYYY-MM-DD${inputHelpText}`;
    }

    if (type === 'password') {
        const inputHelpText = helpText ? `${helpText} - ` : '';
        helpTextToRender = `${inputHelpText}(password must be atleast 8 characters long, contain one upper case, one lowercase, one number and one special character [!@#$%^&*_])`;
    }

    if (type === 'email') {
        const inputHelpText = helpText ? `${helpText} - ` : '';
        helpTextToRender = `${inputHelpText}(please enter a valid email address)`;
    }

    let valueToUse;
    switch (type) {
        case 'date':
            valueToUse = dateString;
            break;

        case 'email':
            valueToUse = emailString;
            break;

        case 'password':
            valueToUse = passwordString;
            break;

        default:
            valueToUse = value;
    }

    const renderLabel = type !== 'hidden' && type !== 'checkbox';
    const renderDefaultInput = type !== 'checkbox' && type !== 'radio-group' && type !== 'select';
    return (
        <React.Fragment>
            {renderLabel &&
                <label htmlFor={id} className={`input__label${(isInvalid || invalid) ? ' input__label--invalid' : ''}`}>
                    {labelText}{!!helpTextToRender && (
                        <React.Fragment>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style={{ stroke: 'currentColor', fill: 'none', strokeWidth: '2px', marginLeft: '3px', width: '17px' }}>
                                <path d="M23,11.811A11.162,11.162,0,0,1,12,23,10.837,10.837,0,0,1,1,12.192,11.162,11.162,0,0,1,12,1,10.838,10.838,0,0,1,23,11.811Z" />
                                <line x1="12" y1="14.005" x2="12" y2="7.005" />
                                <path d="M11.991,16.005a.245.245,0,0,0-.241.255.254.254,0,0,0,.253.245h0a.246.246,0,0,0,.241-.255A.253.253,0,0,0,12,16.005h-.005" />
                            </svg>
                            <span className="input__help-text">{helpTextToRender}</span>
                        </React.Fragment>
                    )}
                </label>
            }
            {renderDefaultInput && (
                <input
                    type={inputType}
                    id={id}
                    name={id}
                    value={String(valueToUse)}
                    onChange={handleChange}
                    placeholder={placeholder || ''}
                    className={`input${(isInvalid || invalid) ? ' input--invalid' : ''}`}
                    disabled={disabled}
                    inputMode={(isMobile && type === 'date') ? 'decimal' : undefined}
                />
            )}
            {type === 'select' && (
                <select
                    id={id}
                    name={id}
                    value={String(valueToUse)}
                    onChange={handleChange}
                    placeholder={placeholder || ''}
                    className={`input${(isInvalid || invalid) ? ' input--invalid' : ''}`}
                    disabled={disabled}
                >
                    <option value="">Please select...</option>
                    {options.map((option) => <option value={option.value} key={option.value}>{option.label}</option>)}
                </select>
            )}
            {type.toLowerCase() === 'radio-group' && options.map((option) => {
                const optionId = `${id}-${option.label}`;
                return (
                    <React.Fragment key={optionId}>
                        <input
                            type="radio"
                            id={optionId}
                            name={id}
                            value={String(option.value)}
                            onChange={(e) => onChange(e.target.value)}
                            className="input"
                            checked={option.value === String(value)}
                            disabled={disabled}
                        />
                        <label htmlFor={optionId} className="input__label">{option.label}</label>
                    </React.Fragment>
                )
            })}
            {type.toLowerCase() === 'checkbox' && (
                <React.Fragment>
                    <div className="input__checkbox-container">
                        <input
                            type="checkbox"
                            id={id}
                            name={id}
                            checked={Boolean(value)}
                            onChange={(e) => onChange(Boolean(e.target.checked))}
                            placeholder={placeholder || ''}
                            className="input input--checkbox"
                            disabled={disabled}
                        />
                        <label htmlFor={id} className="input__label input__label--checkbox">{labelText}</label>
                    </div>
                </React.Fragment>
            )}
        </React.Fragment>
    )
}

export default Input;