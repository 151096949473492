import './ReleaseNotes.css'
import { FormEvent, useEffect, useState } from "react"
import * as api from '../lib/api'
import { SetRelease } from './pages/ViewReleasePage'

interface ReleaseNotesProps {
    releaseId: string
    releaseNotes: string
    setError: api.SetError
    setRelease: SetRelease
}

const ReleaseNotes: React.FC<ReleaseNotesProps> = ({ releaseNotes, setError, setRelease, releaseId }) => {
    const [editedReleaseNotes, setEditedReleaseNotes] = useState(releaseNotes)

    const handleFormSubmit = async (e: FormEvent) => {
        e.preventDefault()
        const updatedRelease = await api.release.updateReleaseNotes(setError, releaseId, editedReleaseNotes)
        if (updatedRelease) {
            setRelease(updatedRelease)
        }
    }

    const getGeneratedReleaseNotes = async () => {
        const res = await api.release.getReleaseNotes(setError, releaseId)
        if (res) {
            setEditedReleaseNotes(res)
        }
    }

    useEffect(() => {
        if (!editedReleaseNotes) {
            getGeneratedReleaseNotes()
        }
    }, [releaseNotes])
    return (
        <form action="" onSubmit={handleFormSubmit}>
            <div className="release-notes__header">
                <label htmlFor="release-notes">Release Notes</label>
                <button className="button button--small" disabled={releaseNotes === editedReleaseNotes}>Save</button>
            </div>
            <textarea
                name="release-notes"
                id="release-notes"
                className="input release-notes__input"
                value={editedReleaseNotes}
                onChange={(e) => setEditedReleaseNotes(e.target.value)}
            ></textarea>
        </form>
        
    )
}

export default ReleaseNotes