// data-validation.ts
import Validator, { ValidationError } from 'fastest-validator';

const validator = new Validator();

const emailSchema = {
    email: { type: 'email' }
}

const emailValidator = validator.compile(emailSchema);

export const validateEmail = (email: string): true | ValidationError[] => {
    return emailValidator({ email });
}


// Special characters (!@#$%^&*)
// Lower case (a-z), upper case (A-Z) and numbers (0-9)
// Must have 8 characters in length
// Non-empty password required

const passwordRegex = {
    specialCharacters: /[!|@|#|$|%|^|&|*|_]/,
    lowerCase: /[a-z]/,
    upperCase: /[A-Z]/,
    number: /[0-9]/
}

const passwordSchema = {
    password: { 
        type: 'string',
        min: 8
    }
}

const passwordLengthValidator = validator.compile(passwordSchema);

export const validatePassword = (password: string) => {
    const validationRes = {
        length: passwordLengthValidator({ password }) ? true : 'password must be at least 8 characters in length',
        lowerCase: passwordRegex.lowerCase.test(password) ? true : 'password must contain atleast one lower case letter [a-z]',
        upperCase: passwordRegex.upperCase.test(password) ? true : 'password must contain atleast one upper case letter [A-Z]',
        number: passwordRegex.number.test(password) ? true : 'password must contain atleast one number [0-9]',
        specialCharacters: passwordRegex.specialCharacters.test(password) ? true : 'password must contain atleast one special character [!@#$%^&*_]'
    }

    let isValid = true;
    const validationKeys = Object.keys(validationRes);
    validationKeys.forEach((key) => {
        // @ts-ignore
        if (validationRes[key] !== true) {
            isValid = false;
        }
    });

    return isValid ? true : validationRes;
}

// parseDate
export const ddMmYyyyRegex = /[0-9][0-9].[0-9][0-9].[1-9][0-9][0-9][0-9]/;
export const yyyyMmDdRegex = /[1-9][0-9][0-9][0-9].[0-9][0-9].[0-9][0-9]/;

export const parseDate = (inputDate: string) => {
    if (!inputDate) {
        return;
    }

    // @ts-ignore
    if (inputDate instanceof Date && !isNaN(inputDate)) {
        return inputDate;
    }

    let returnDate;
    if (ddMmYyyyRegex.test(inputDate)) {
        const delimiter = inputDate[2];
        const dateParts = inputDate.split(delimiter);
        returnDate = new Date(`${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`);
    }

    if (yyyyMmDdRegex.test(inputDate)) {
        const delimiter = inputDate[4];
        const dateParts = inputDate.split(delimiter);
        returnDate = new Date(`${dateParts[0]}-${dateParts[1]}-${dateParts[2]}`);
    }

    if (returnDate) {
        try {
            returnDate.toISOString();
            return returnDate;
        } catch (error) {
            return;
        }
    }

    return;
}
