import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as api from './lib/api'
import LoginPage from './components/pages/LoginPage'

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

const handleUnAuth: api.SetError = (error?: Error) => {
  ReactDOM.render(
    <React.StrictMode>
      <LoginPage />
    </React.StrictMode>,
    document.getElementById('root')
  );
}


export const render = () => api.user.getUser(handleUnAuth).then((res) => {
  if (!res) {
    return
  }
  
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById('root')
  );
})

render()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
