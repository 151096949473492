import './RepoDiffTile.css'
import { Fragment } from "react";
import RepoDiff from "../lib/api/interfaces/repo-diff.interface";

interface RepoDiffTileProps {
    repoDiff: RepoDiff
}

const RepoDiffTile: React.FC<RepoDiffTileProps> = ({ repoDiff }) => {

    return (
        <div className={`repo-diff-tile ${repoDiff.hasChanges ? 'repo-diff-tile--changes' : 'repo-diff-tile--no-changes'}`}>
            <div>
                <span className="repo-diff-tile__repo-name">{repoDiff.repo}</span>
                {!repoDiff.hasChanges && (
                    <span className="repo-diff-tile__changes-title">No Changes</span>
                )}
                {!!repoDiff.hasChanges && (
                    <Fragment>
                        <span className="repo-diff-tile__changes-title">Changes made by:</span>
                        <ul>
                            {repoDiff.changeAuthors.map((author, index) => <li key={`${repoDiff.repo}-${author}-${index}`}>{author}</li>)}
                        </ul>
                    </Fragment>
                )}
            </div>
            {!!repoDiff.diffUrl && <a className="repo-diff-tile__diff-link" href={repoDiff.diffUrl} target="_blank">View diff</a>}    
        </div>
    )
}
export default RepoDiffTile
