
interface ErrorProps {
    error?: Error
}
const ErrorMessage: React.FC<ErrorProps> = ({ error }) => {
    return (
        <p className={`error-text${error ? ' error-text--show' : ''}`}><span>{error?.message}</span><br />{JSON.stringify(error)}</p>
    )
}

export default ErrorMessage
