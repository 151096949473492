import './PRList.css'
import PRDto from "../lib/api/interfaces/pr.dto.interface"

interface PRListProps {
    PRs: PRDto[]
}

const PRList: React.FC<PRListProps> = ({ PRs }) => {

    return (
        <div>
            <h3>PRs</h3>
            {PRs.map((PR) => (
                <div className="pr-list__item" key={PR.repo}>
                    <p>{PR.repo}</p>
                    {!PR.url && <p className="pr-list__info">No updates</p>}
                    {!!PR.url && <a href={PR.url} target="_blank" rel="noreferrer">Pull Request</a>}
                </div>
            ))}
        </div>
    )
}

export default PRList
