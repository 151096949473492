import { SetError, axiosInstance } from "."
import RepoDiff from "./interfaces/repo-diff.interface"

export const getRepoDiffs = async (setError: SetError, head: string, base: string) => {
    try {
        const { data } = await axiosInstance.get<RepoDiff[]>(`/dashboard/compare/all/${base}/${head}`)
        setError(undefined)

        return data
    } catch (error) {
        setError(error)
    }
}