import { axiosInstance, SetError } from "."
import Customer from "./interfaces/customer.interface"
import User from "./interfaces/user.interface"
import * as api from '.'

export const getUser = async (setError: SetError) => {
    api.auth.getAccessToken()
    try {
        const { data } = await axiosInstance.get<{ customer: Customer, user: User }>('/user/me')
        return data
    } catch (error) {
        console.error(error)
        setError(error)
    }
}